import styles from './HowItWorks.module.scss';

import { Process, FrequentlyAskedQuestions, YourTimeIsPrecious } from '@components/Homepage/HowItWorksV1';
import Button from '@components/shared/Button/Button';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function HowItWorks() {
  return (
    <div className={styles['hiw-container']}>
      <div className={styles['hiw-container__background-gradient']} />
      <Process />
      <FrequentlyAskedQuestions />
      <Button
        variant="primary"
        className={styles['craft-trip']}
        href="/request-to-travel"
        onClick={() => {
          elementClicked({
            actionOutcome: 'INTERNALLINK',
            outboundUrl: 'https://www.elsewhere.io/request-to-travel',
            webElement: {
              elementType: 'Button',
              location: 'Homepage',
              name: 'HIW CTA',
              position: 'HOWITWORKS V2',
              text: 'Get started'
            }
          });
        }}
      >
        Get started
      </Button>
      <YourTimeIsPrecious />
    </div>
  );
}
