import React, { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { register } from 'swiper/element/bundle'; // eslint-disable-line
import { Divider, Fade, Typography, useMediaQuery } from '@mui/material';
import Link from 'next/link';

import styles from './Search.module.scss';

import theme from '@styles/theme';
import routes from '@constants/routes';
import Select from '@components/shared/Form/Select/Select';
import { elementClicked } from '@utils/sendEvent';

const popularDestinations = [
  'Italy',
  'Japan',
  'The Galapagos',
  'South Africa',
  'Portugal',
  'Thailand',
  'Costa Rica',
  'Tanzania',
  'Vietnam',
  'Scotland',
  'India',
  'Spain',
  'France'
];

const Search = ({ regionsStore }) => {
  const router = useRouter();
  const handleSelectChange = ({ value }) => {
    router.push(routes.destinations.details(value.value));
  };

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const swiperRef = useRef(null);

  useEffect(() => {
    register();

    const params = {
      slidesPerView: 1,
      centerslides: true,
      effect: 'creative',
      speed: 1000,
      creativeEffect: {
        prev: {
          translate: [0, '-100%', 0],
          rotate: [0, 0, 0]
        },
        next: {
          translate: [0, '100%', 0],
          rotate: [0, 0, 0]
        },
        perspective: true
      },

      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      },
      loop: true,

      injectStyles: [
        `
        .swiper {
          height: 34px;
          width: 150px;
          font-size: 16px;

          @media (max-width: 900) {
            height: 25px;
            width: 140px;
            padding-bottom: 5px;
          }
        }

        `
      ]
    };

    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, []);

  return (
    <Fade in={true} timeout={1000}>
      <div className="hero-search v1">
        <div className="form-block w-form">
          <form
            className="hero-form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span className={styles['search-button']}></span>
            <div
              onClick={() =>
                elementClicked({
                  actionOutcome: 'SEARCH',
                  outboundUrl: '',
                  webElement: {
                    elementType: 'Button',
                    location: 'Homepage',
                    name: 'Hero - Search Bar v1',
                    position: '0',
                    text: isSmall ? 'Where to?' : 'Where do you want to go?'
                  }
                })
              }
            >
              <Select
                className="homepage-hero__select hero-input v1 w-input"
                placeholder={isSmall ? 'Where to?' : 'Where do you want to go?'}
                aria-label="Search destination"
                options={regionsStore.groupedSelectOptions('slug')}
                components={{
                  DropdownIndicator: () => null
                }}
                onChange={handleSelectChange}
              />
            </div>
            <Divider orientation="vertical" flexItem sx={{ mr: 1.5, my: 1 }} />
            <div className={styles['swiper-container']}>
              <Typography className={styles['popular-now']}>Popular now</Typography>
              <swiper-container init="false" ref={swiperRef}>
                {popularDestinations.map((destination, i) => (
                  <swiper-slide key={`review-${i}`} virtualIndex={i}>
                    <Link href={routes.destinations.details(destination.toLowerCase().replaceAll(' ', '-'))}>
                      <a
                        className={styles['swiper-destination']}
                        onClick={() => {
                          elementClicked({
                            actionOutcome: 'INTERNALLINK',
                            outboundUrl: routes.destinations.details(destination.toLowerCase().replaceAll(' ', '-')),
                            webElement: {
                              elementType: 'button',
                              location: 'Homepage',
                              name: 'Hero - Popular Destination',
                              position: '1',
                              text: destination
                            }
                          });
                        }}
                      >
                        {destination}
                      </a>
                    </Link>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div>
          </form>
        </div>
      </div>
    </Fade>
  );
};

export default Search;
