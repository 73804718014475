import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * IntersectionObserver hook borrowed from LP's DFE2
 * Set `ref={setNodeRef}` on an element you want to observe.
 * As the element enters/exits the viewport `isVisible` will toggle
 * with the value of true/false.
 * If options isn't specified, the observer
 * uses the document's viewport as the root,
 * with no margin, and a 0% threshold
 * (meaning that even a one-pixel change is enough to trigger a callback)
 * more about intersectionObserver here:
 * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
 */

function useElemRef(processNode: (node: HTMLElement) => HTMLElement) {
  const [node, setNode] = useState(null);
  const setRef = useCallback(
    (newNode: HTMLElement) => {
      setNode(processNode(newNode));
    },
    [processNode]
  );
  return [node, setRef];
}

export function useIntersectionObserver(options: any = {}, defaultVisibility: boolean = false, triggerOnce = false) {
  const [isVisible, setIsVisible] = useState(defaultVisibility);
  const [nodeRef, setNodeRef] = useElemRef((node) => node);
  const observerRef = useRef<IntersectionObserver>();

  const checkIsVisible = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (triggerOnce) {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observerRef.current.unobserve(entry.target);
      }
    } else {
      setIsVisible(entry.isIntersecting);
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(checkIsVisible, {
      root: null,
      ...options
    });
    if (nodeRef) observerRef.current.observe(nodeRef);

    return () => {
      if (nodeRef) observerRef.current.unobserve(nodeRef);
    };
  }, [nodeRef, options]);

  return {
    isVisible,
    setNodeRef,
    nodeRef
  };
}
