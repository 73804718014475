import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import styles from './HowItWorksCalculator.module.scss';

import Helpers from '@utils/helpers';
import { elementClicked } from '@utils/sendEvent';

const hourlyRate = 40;

const HowItWorksCalculator = () => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = ({ target: { value } }) => {
    if (Helpers.valueIsNumeric(value)) {
      setInputValue(value);
    }
  };

  const costOfResearch = useMemo(() => (!inputValue ? 0 : inputValue * hourlyRate), [inputValue]);

  return (
    <div id="calculator-form" className={styles['calculator-container']}>
      <form data-name="Email Form 2" className={styles['form-container']}>
        <label htmlFor="calc-hours" className={styles['headlines']}>
          <h4>The average international trip takes a minimum of 40 hours of research</h4>
          <p>Your hourly worth [in $]</p>
        </label>
        <div className={styles['calc-input-container']}>
          <input
            type="text"
            className={styles['calc-input']}
            maxLength={256}
            placeholder={45}
            value={inputValue}
            onChange={handleInputChange}
            autoComplete="off"
            onClick={() => {
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'Input',
                  location: 'Homepage',
                  name: 'CTA',
                  position: 'HOWITWORKS-EXPERTS',
                  text: 'Craft your trip CTA'
                }
              });
            }}
          />
          <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="submit-button w-button" />
          <p className={styles['equal-sign']}>=</p>
          <div id="calc-result" className={styles['calc-result']}>
            {`$${costOfResearch}`}
          </div>
          <p className={styles['cost-of-research']}>Cost of research</p>
        </div>
      </form>
    </div>
  );
};

export default HowItWorksCalculator;
