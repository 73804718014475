import { Fragment, useState } from 'react';
import classnames from 'classnames';

import styles from './LocalExperts.module.scss';

import { FAQ_LOCAL_EXPERTS } from '@constants/homepage/HowItWorks';
import HowItWorksCalculator from '@components/Homepage/Calculator/HowItWorksCalculator';
import Icon from '@components/shared/Icon/Icon';
import Button from '@components/shared/Button/Button';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function LocalExperts() {
  const [openDropdown, setOpenDropdown] = useState(0);

  return (
    <div className={styles['local-experts-container']}>
      <div className={styles['text-container-wrapper']}>
        <div className={styles['text-container-left']}>
          <h2>Frequently asked questions</h2>
        </div>
        <div className={styles['text-container-right']}>
          {FAQ_LOCAL_EXPERTS.map((q, i) => {
            const { h3, p } = q;
            return (
              <>
                <button
                  className={styles['dropdown']}
                  key={h3}
                  type="button"
                  onClick={() => {
                    if (openDropdown === i) {
                      setOpenDropdown(-1);
                    } else {
                      setOpenDropdown(i);
                    }
                    elementClicked({
                      actionOutcome: 'EXPAND',
                      outboundUrl: 'null',
                      webElement: {
                        elementType: 'Button',
                        location: 'Homepage',
                        name: `FAQ - ${i + 1}`,
                        position: 'HOWITWORKS-EXPERTS',
                        text: h3
                      }
                    });
                    elementViewed({
                      webElement: {
                        elementType: 'Button',
                        location: 'Homepage',
                        name: `FAQ - ${i + 1}`,
                        position: 'HOWITWORKS-EXPERTS',
                        text: h3
                      }
                    });
                  }}
                >
                  <div className={styles['dropdown-title']}>
                    <h4>{h3}</h4>
                    <Icon
                      icon="arrow-up"
                      size={16}
                      style={openDropdown === i ? { transform: 'rotate(0)' } : { transform: 'rotate(180deg)' }}
                    />
                  </div>

                  {i === 0 || i === 1 ? <hr /> : null}
                </button>
                <div
                  className={classnames(
                    styles['dropdown-content'],
                    openDropdown === i ? styles['dropdown-open'] : styles['dropdown-closed']
                  )}
                >
                  <p>
                    {p.map((text) => (
                      <Fragment key={text}>
                        {text}
                        <br />
                        <br />
                      </Fragment>
                    ))}
                  </p>
                  <div className={styles['calculator']}>
                    {openDropdown === 2 && i === 2 && <HowItWorksCalculator />}
                  </div>
                </div>
              </>
            );
          })}
          <Button
            variant="primary"
            className={styles['craft-trip']}
            href="/request-to-travel"
            onClick={() => {
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'Button',
                  location: 'Homepage',
                  name: 'CTA',
                  position: 'HOWITWORKS-EXPERTS',
                  text: 'craft your trip CTA'
                }
              });
            }}
          >
            Craft your trip
          </Button>
        </div>
      </div>
    </div>
  );
}
