import DesktopBackgroundSicily from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-sicily.jpg';
import MobileBackgroundSicily from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-sicily-mobile.jpg';
import DesktopBackgroundMorocco from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-morocco.jpg';
import MobileBackgroundMorocco from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-morocco-mobile.jpg';
import DesktopBackgroundTanzania from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-tanzania.jpg';
import MobileBackgroundTanzania from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-tanzania-mobile.jpg';
import DesktopBackgroundVietnam from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-vietnam.jpg';
import MobileBackgroundVietnam from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-vietnam-mobile.jpg';
import DesktopBackgroundBrazil from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-brazil.jpg';
import MobileBackgroundBrazil from '@public/img/homepage/itineraries/backgrounds/itinerary-bg-brazil-mobile.jpg';
import ScrollSicily from '@public/img/homepage/itineraries/scrolls/itinerary-sicily.png';
import ScrollMorocco from '@public/img/homepage/itineraries/scrolls/itinerary-morocco.png';
import ScrollTanzania from '@public/img/homepage/itineraries/scrolls/itinerary-tanzania.png';
import ScrollVietnam from '@public/img/homepage/itineraries/scrolls/itinerary-vietnam.png';
import ScrollBrazil from '@public/img/homepage/itineraries/scrolls/itinerary-brazil.png';

// The constants for the itineraries section of the homepage
// If we want to update these details in production, we'll need to update here too
// Note these link to *production* itineraries

export const ITINERARIES = [
  {
    backgroundImg: { desktop: DesktopBackgroundMorocco.src, mobile: MobileBackgroundMorocco.src },
    scrollImg: { url: ScrollMorocco.src, height: 2952 },
    itinerary: {
      url: 'https://www.elsewhere.io/trip/itinerary/31252b5e-3a99-4ba3-a043-e63c766b1889',
      traveler: 'Rachel',
      destination: 'Morocco'
    }
  },
  {
    backgroundImg: { desktop: DesktopBackgroundSicily.src, mobile: MobileBackgroundSicily.src },
    scrollImg: { url: ScrollSicily.src, height: 2685 },
    itinerary: {
      url: 'https://www.elsewhere.io/trip/itinerary/f64cdaff-3191-48aa-ac44-08bb4860cee7',
      traveler: 'Dan',
      destination: 'Sicily'
    }
  },
  {
    backgroundImg: { desktop: DesktopBackgroundTanzania.src, mobile: MobileBackgroundTanzania.src },
    scrollImg: { url: ScrollTanzania.src, height: 2911 },
    itinerary: {
      url: 'https://www.elsewhere.io/trip/itinerary/824a152a-cbd1-494e-a494-82311e4f8189',
      traveler: 'Leanne',
      destination: 'Tanzania'
    }
  },
  {
    backgroundImg: { desktop: DesktopBackgroundBrazil.src, mobile: MobileBackgroundBrazil.src },
    scrollImg: { url: ScrollBrazil.src, height: 2943 },
    itinerary: {
      url: 'https://www.elsewhere.io/trip/itinerary/6de4659d-6162-4a6a-8609-b529516e3612',
      traveler: 'Kimberly',
      destination: 'Brazil'
    }
  },
  {
    backgroundImg: { desktop: DesktopBackgroundVietnam.src, mobile: MobileBackgroundVietnam.src },
    scrollImg: { url: ScrollVietnam.src, height: 2977 },
    itinerary: {
      url: 'https://www.elsewhere.io/trip/itinerary/f6ec4319-b592-4bb8-9c5c-01f6cad2db3a',
      traveler: 'Vanessa',
      destination: 'Vietnam'
    }
  }
];
