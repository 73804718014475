import React from 'react';

export function IconPreferences({ width = 24, height = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-preferences" transform="translate(2 5)">
        <rect
          id="Rectangle_1155"
          data-name="Rectangle 1155"
          width="32"
          height="32"
          transform="translate(-2 -5)"
          fill="none"
        />
        <path
          id="Path_1823"
          data-name="Path 1823"
          d="M8.8,0H0"
          transform="translate(16.4 2.252)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1822"
          data-name="Path 1822"
          d="M8.8,0H0"
          transform="translate(2 2.252)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1703"
          data-name="Path 1703"
          d="M2.405,0A2.405,2.405,0,1,1,0,2.405,2.405,2.405,0,0,1,2.405,0Z"
          transform="translate(10.8 -0.205)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1821"
          data-name="Path 1821"
          d="M13.6,0H0"
          transform="translate(11.6 11.052)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1820"
          data-name="Path 1820"
          d="M4.8,0H0"
          transform="translate(2 11.052)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1704"
          data-name="Path 1704"
          d="M2.405,0A2.405,2.405,0,1,1,0,2.405,2.405,2.405,0,0,1,2.405,0Z"
          transform="translate(6.789 8.595)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1819"
          data-name="Path 1819"
          d="M4,0H0"
          transform="translate(21.2 19.8)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1818"
          data-name="Path 1818"
          d="M14.4,0H0"
          transform="translate(2 19.8)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1705"
          data-name="Path 1705"
          d="M2.405,0A2.405,2.405,0,1,1,0,2.405,2.405,2.405,0,0,1,2.405,0Z"
          transform="translate(16.394 17.395)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
