import React from 'react';

export function IconStarItinerary({ width = 24, height = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-star-itinerary" transform="translate(-454 -3502.332)">
        <rect
          id="Rectangle_1154"
          data-name="Rectangle 1154"
          width="32"
          height="32"
          transform="translate(454 3502.332)"
          fill="none"
        />
        <g id="Group_2321" data-name="Group 2321" transform="translate(456.305 3510.868)">
          <path
            id="Path_1706"
            data-name="Path 1706"
            d="M23.338,17.339v1.881a1.13,1.13,0,0,1-.625,1.01L17.569,22.8a2.26,2.26,0,0,1-2.02,0l-4.759-2.38a2.26,2.26,0,0,0-2.02,0L4.635,22.492A1.13,1.13,0,0,1,3,21.478V7.057a1.13,1.13,0,0,1,.625-1.01L8.769,3.474a2.26,2.26,0,0,1,2.02,0l2.427,1.214"
            transform="translate(-3 -3.236)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1707"
            data-name="Path 1707"
            d="M15,5.764V18.43"
            transform="translate(-1.441 1.375)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1708"
            data-name="Path 1708"
            d="M9,3.236V20.184"
            transform="translate(-2.221 -3.236)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <g id="Group_2322" data-name="Group 2322" transform="translate(469.273 3506.716)">
          <path
            id="Path_1709"
            data-name="Path 1709"
            d="M7.849,11.947A1.474,1.474,0,0,0,6.79,10.889L2.269,9.723a.368.368,0,0,1,0-.709L6.79,7.848A1.474,1.474,0,0,0,7.849,6.789L9.015,2.269a.368.368,0,0,1,.71,0l1.165,4.521a1.474,1.474,0,0,0,1.059,1.059l4.521,1.165a.368.368,0,0,1,0,.71l-4.521,1.165a1.474,1.474,0,0,0-1.059,1.059L9.723,16.468a.368.368,0,0,1-.71,0Z"
            transform="translate(-2.002 -2)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1710"
            data-name="Path 1710"
            d="M20,3V5.947"
            transform="translate(-6.738 -2.263)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1711"
            data-name="Path 1711"
            d="M20.947,5H18"
            transform="translate(-6.211 -2.789)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
