import { appDomain } from '@constants/constants';

const configHomePage = {
  json_ld: '',
  meta_description: 'Craft your trip with local experts who know their destination better than anyone else.',
  meta_keywords: '',
  meta_title: 'Wildly unique experiences. Positive impact travel.',
  og_description: 'Craft your trip with local experts who know their destination better than anyone else.',
  og_image: `${appDomain}img/elsewhere-og.png`,
  og_url: appDomain,
  og_title: 'Wildly unique experiences. Positive impact travel.',
  twitter_description: 'Craft your trip with local experts who know their destination better than anyone else.',
  twitter_image: `${appDomain}img/elsewhere-og.png`,
  twitter_title: 'Wildly unique experiences. Positive impact travel.',
  benefits_column_1_description: '',
  benefits_column_1_item_1: '',
  benefits_column_1_item_2: '',
  benefits_column_1_item_3: '',
  benefits_column_1_title: '',
  benefits_column_2_description: '',
  benefits_column_2_item_1: '',
  benefits_column_2_item_2: '',
  benefits_column_2_item_3: '',
  benefits_column_2_title: '',
  benefits_subtitle: '',
  benefits_title: '',
  calculator_label: '',
  calculator_result_title: '',
  calculator_subtitle: '',
  calculator_title: '',
  chart_1_button_text: '',
  chart_1_description_1: '',
  chart_1_description_2: '',
  chart_1_description_3: '',
  chart_1_title: '',
  chart_2_description_1: '',
  chart_2_description_2: '',
  chart_2_description_3: '',
  chart_2_subtitle: '',
  chart_2_title: '',
  chart_subtitle: '',
  chart_tab_1_title: '',
  chart_tab_2_title: '',
  chart_title: '',
  commitments_subtitle: '',
  commitments_title: '',
  hero_background_image: null,
  hero_search_placeholder: '',
  hero_subtitle: '',
  hero_title: '',
  hero_video_mp4: null,
  hero_video_poster: null,
  hero_video_webm: null,
  how_it_works_description: '',
  how_it_works_extra_subtitle: '',
  how_it_works_extra_title: '',
  how_it_works_step_1: '',
  how_it_works_step_2: '',
  how_it_works_step_3: '',
  how_it_works_title: '',
  how_it_works_video_message: '',

  qa_button_text: '',
  qa_button_url: '',
  qa_title: '',
  testimonials_avatar: null,
  testimonials_background_image: null,
  testimonials_description: '',
  testimonials_location: '',
  testimonials_name: '',
  testimonials_role: '',
  testimonials_section_title: '',
  testimonials_testimonial_title: '',

  ups_subtitle: '',
  ups_title: ''
};

export default configHomePage;
