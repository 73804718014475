import React from 'react';

export function IconMessages({ width = 24, height = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-messages" transform="translate(-338 -3488)">
        <rect
          id="Rectangle_1153"
          data-name="Rectangle 1153"
          width="32"
          height="32"
          transform="translate(338 3488)"
          fill="none"
        />
        <g id="Group_2400" data-name="Group 2400" transform="translate(340.139 3490.138)">
          <path
            id="Path_1701"
            data-name="Path 1701"
            d="M18.634,11.7a2.772,2.772,0,0,1-2.772,2.772H7.545L2,20.02V4.772A2.78,2.78,0,0,1,4.772,2H15.862a2.772,2.772,0,0,1,2.772,2.772Z"
            transform="translate(-2 -2)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1702"
            data-name="Path 1702"
            d="M21.089,9h2.772a2.772,2.772,0,0,1,2.772,2.772V27.02l-5.545-5.545H12.772A2.772,2.772,0,0,1,10,18.7V17.317"
            transform="translate(1.089 0.703)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
