import React from 'react';

export function IconSchedule({ width = 24, height = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-schedule" transform="translate(-572 -0.809)">
        <rect
          id="Rectangle_1156"
          data-name="Rectangle 1156"
          width="32"
          height="32"
          transform="translate(572 0.809)"
          fill="none"
        />
        <g id="Group_2327" data-name="Group 2327" transform="translate(577.408 4.809)">
          <path
            id="Path_1712"
            data-name="Path 1712"
            d="M8,2V6.708"
            transform="translate(-2.115 -2)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1713"
            data-name="Path 1713"
            d="M16,2V6.708"
            transform="translate(-0.7 -2)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1716"
            data-name="Path 1716"
            d="M2.354,0H18.831a2.354,2.354,0,0,1,2.354,2.354V18.831a2.354,2.354,0,0,1-2.354,2.354H2.354A2.354,2.354,0,0,1,0,18.831V2.354A2.354,2.354,0,0,1,2.354,0Z"
            transform="translate(0 2.354)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1714"
            data-name="Path 1714"
            d="M3,10H24.185"
            transform="translate(-3 -0.584)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1715"
            data-name="Path 1715"
            d="M9,16.566l2.566,2.566L16.7,14"
            transform="translate(-1.938 -0.287)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
