import { useState, Fragment } from 'react';
import classnames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';

import styles from './Process.module.scss';

import { PROCESS_OPTIONS, PROCESS_OPTIONS_MAP } from '@constants/homepage/HowItWorks';
import Button from '@components/shared/Button/Button';
import { elementClicked, elementViewed } from '@utils/sendEvent';
import { useMobile } from '@utils/useMobile';

export function Process() {
  const [selectedOption, setSelectedOption] = useState(0);
  const isMobile = useMobile();
  const handleClick = (i) => {
    if (isMobile && window.innerWidth < 600) {
      if (selectedOption === i) {
        setSelectedOption(-1);
      } else {
        setSelectedOption(i);
      }
    } else {
      setSelectedOption(i);
    }
  };

  return (
    <>
      <div className={styles['process-container']}>
        <div className={styles['panel-containers']}>
          <div className={styles['left-panel']}>
            <div className={styles['tabs-container']} role="tab-panel">
              {PROCESS_OPTIONS.map((option, i) => (
                <Fragment key={option}>
                  <button
                    type="button"
                    role="tab"
                    className={classnames(styles['tab-button'], {
                      [styles['tab-button-active']]: selectedOption === i
                    })}
                    onClick={() => {
                      handleClick(i);
                      elementClicked({
                        actionOutcome: 'POP',
                        outboundUrl: 'null',
                        webElement: {
                          elementType: 'button',
                          location: 'homepage',
                          name: 'steps',
                          position: 'HOWITWORKS-PROCESS',
                          text: option
                        }
                      });
                      elementViewed({
                        webElement: {
                          elementType: 'button',
                          location: 'homepage',
                          name: 'steps',
                          position: 'HOWITWORKS-PROCESS',
                          text: option
                        }
                      });
                    }}
                  >
                    <div className={styles['tab-button-inner']}>
                      <div className={styles['tab-button-text']}>
                        <p className={styles['tab-button-number']}>{i + 1}</p>
                        {option}
                      </div>
                    </div>
                  </button>
                  {PROCESS_OPTIONS_MAP.map((option, index) => {
                    const { p, h3, bulletpoints } = option;
                    return (
                      index === i && (
                        <div
                          key={h3}
                          className={classnames(styles['mobile-panel-text'], {
                            [styles['mobile-panel-text-selected']]: selectedOption === i
                          })}
                        >
                          <p>{p}</p>
                          <h3>{h3}</h3>
                          <ul>
                            {bulletpoints.map((bullet) => (
                              <li key={bullet}>
                                <CheckIcon fontSize="small" /> {bullet}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                    );
                  })}
                </Fragment>
              ))}
            </div>
            <Button
              variant="primary"
              className={styles['craft-trip']}
              href="/request-to-travel"
              onClick={() => {
                elementClicked({
                  actionOutcome: 'INTERNALLINK',
                  outboundUrl: 'null',
                  webElement: {
                    elementType: 'Button',
                    location: 'Homepage',
                    name: 'CTA',
                    position: 'HOWITWORKS-PROCESS',
                    text: 'craft your trip CTA'
                  }
                });
              }}
            >
              Craft your trip
            </Button>
          </div>

          <div className={styles['right-panel']}>
            {PROCESS_OPTIONS_MAP.map((option, i) => {
              const { p, h3, bulletpoints } = option;
              return (
                i === selectedOption && (
                  <div className={styles['right-panel-text']} key={h3}>
                    <p>{p}</p>
                    <h3 className={styles['right-panel-header']}>{h3}:</h3>
                    <ul>
                      {bulletpoints.map((bullet) => (
                        <li key={bullet}>
                          <CheckIcon fontSize="small" /> {bullet}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
