import React, { useRef, useEffect, useState, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Fade, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';

import styles from './LocalExperts.module.scss';
import { PrevButton, NextButton, usePrevNextButtons } from './CarouselArrowButtons';

import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import theme from '@styles/theme';
import { localExpertData } from '@constants/constants';
import routes from '@constants/routes';
import { elementClicked, elementViewed } from '@utils/sendEvent';

const numberWithinRange = (number, min, max) => Math.min(Math.max(number, min), max);

const EmblaCarousel = (props) => {
  const router = useRouter();
  const videoRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(5);
  const [showVideoIndex, setShowVideoIndex] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const { setNodeRef, isVisible } = useIntersectionObserver(
    {
      threshold: 0.4
    },
    false
  );

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: activeSlideIndex,
    loop: true,
    watchFocus: true,
    watchSlides: true
  });
  const tweenNodes = useRef([]);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  const setTweenNodes = useCallback((emblaApi) => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => slideNode.querySelector('.embla__slide__number'));
  }, []);

  const tweenScale = useCallback((emblaApi, eventName) => {
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const slidesInView = emblaApi.slidesInView();
    const isScrollEvent = eventName === 'scroll';

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress + 2);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        const isCenterSlide = slideIndex === emblaApi.selectedScrollSnap();
        const tweenValue = isCenterSlide ? 1 : 0.9;
        const scale = numberWithinRange(tweenValue, 0, 2).toString();
        const tweenNode = tweenNodes.current[slideIndex];
        tweenNode.style.transition = 'transform 0.5s';
        tweenNode.style.transform = `scale(${scale})`;

        if (isCenterSlide) {
          tweenNode.classList.add(styles['expert-active']);
          tweenNode.classList.remove(styles['expert-inactive']);
        } else {
          tweenNode.classList.remove(styles['expert-active']);
          tweenNode.classList.add(styles['expert-inactive']);
        }
      });
    });
  }, []);

  const handleVideoClick = (i, expertName) => {
    setShowVideoIndex(i);
    elementClicked({
      actionOutcome: 'PLAY',
      outboundUrl: '',
      webElement: {
        elementType: 'Button',
        location: 'Homepage',
        name: 'Local Expert Video',
        position: '0',
        text: expertName
      }
    });
  };

  const handleVideoToggle = (expertName) => {
    if (isVideoPaused) {
      elementClicked({
        actionOutcome: 'PLAY',
        outboundUrl: '',
        webElement: {
          elementType: 'Button',
          location: 'Homepage',
          name: 'Local Expert Video',
          position: '0',
          text: expertName
        }
      });
      videoRef.current.play();
      setIsVideoPaused(false);
    } else {
      videoRef?.current?.pause();
      setIsVideoPaused(true);
    }
  };

  useEffect(() => {
    if (!emblaApi) return;

    setTweenNodes(emblaApi);
    tweenScale(emblaApi);

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', tweenScale)
      .on('scroll', tweenScale)
      .on('slideFocus', tweenScale)
      .on('select', () => {
        if (videoRef.current) {
          videoRef.current.pause();
          setIsVideoPaused(false);
        }
      });
  }, [emblaApi, tweenScale]);

  useEffect(() => {
    if (isVisible) {
      elementViewed({
        webElement: {
          elementType: 'Button',
          location: 'Homepage',
          name: 'Local Expert Video',
          position: '0',
          text: 'Gunnar'
        }
      });
    }
  }, [isVisible]);

  return (
    <div className="full-page-container overflow-visible">
      <div className={styles['local-experts']}>
        <div className="local-experts__intro">
          <h2 className="local-experts__intro-title">No one does it like {isSmall ? <br /> : ''} our local experts</h2>
          <h5 className="local-experts__intro-text">
            These are award-winning trip planners, not tour guides. They know the secret spots,
            {isSmall ? ' ' : <br />}
            the must do adventures, and have the connections to make anything happen.
          </h5>
        </div>
      </div>

      <section className={styles['embla']}>
        <div className={styles['embla__buttons']}>
          {!isSmall && (
            <>
              <PrevButton
                onClick={() => {
                  onPrevButtonClick();
                  elementClicked({
                    actionOutcome: 'SLIDE',
                    outboundUrl: '',
                    webElement: {
                      elementType: 'Arrow',
                      location: 'Homepage',
                      name: 'Local Expert Arrows (Left)',
                      position: '2',
                      text: 'Previous'
                    }
                  });
                }}
                disabled={prevBtnDisabled}
              />
              <NextButton
                onClick={() => {
                  onNextButtonClick();
                  elementClicked({
                    actionOutcome: 'SLIDE',
                    outboundUrl: '',
                    webElement: {
                      elementType: 'Arrow',
                      location: 'Homepage',
                      name: 'Local Expert Arrows (Right)',
                      position: '2',
                      text: 'Next'
                    }
                  });
                }}
                disabled={nextBtnDisabled}
              />
            </>
          )}
        </div>

        <div className={`${styles['embla']} embla`}>
          <div className={`embla__viewport ${styles['embla__viewport']}`} ref={emblaRef}>
            <div className={`embla__container ${styles['embla__container']}`} ref={setNodeRef}>
              {localExpertData.experts.map((expert, i) => (
                <div key={`expert-${i}`} className={styles['embla__slide']} onClick={() => emblaApi.scrollTo(i, false)}>
                  <div
                    className={`${styles['expert']} embla__slide__number`}
                    style={{
                      backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.005)), url(${expert.image})`,
                      backgroundSize: '100%',
                      backgroundRepeat: 'no-repeat',
                      cursor: 'pointer'
                    }}
                  >
                    {showVideoIndex === i ? (
                      <Fade in={true}>
                        <div>
                          <video
                            ref={videoRef}
                            autoPlay={showVideoIndex == i}
                            playsInline
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: '20px'
                            }}
                            // add onclick to pause/play video on click
                            onClick={() => handleVideoToggle(expert.name)}
                          >
                            <source src={expert.video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>

                          {isVideoPaused && (
                            <img
                              onClick={() => handleVideoToggle(expert.name)}
                              className={styles['pause-video-btn']}
                              src="/icons/icon-24-pause.svg"
                              alt="pause video button"
                            />
                          )}
                        </div>
                      </Fade>
                    ) : (
                      <div className={`${styles['expert-content']} expert-content`}>
                        <div
                          style={{
                            width: '46px',
                            height: '46px',
                            background: '#FFFFFF',
                            borderRadius: '50%',
                            position: 'relative',
                            marginBottom: '20px'
                          }}
                          className={styles['play-video-btn']}
                          onClick={() => handleVideoClick(i, expert.name)}
                        >
                          <div>
                            <img src="/icons/icon-32-play.svg" alt="play video button" />
                          </div>
                        </div>

                        <p>{expert.name}</p>
                        <p>
                          Local expert,
                          <span
                            className={styles['expert__destination']}
                            onClick={() => {
                              elementClicked({
                                actionOutcome: 'INTERNALLINK',
                                outboundUrl: routes.destination(expert.location.toLowerCase()),
                                webElement: {
                                  elementType: 'Link',
                                  location: 'Homepage',
                                  name: 'Local Expert Destination',
                                  position: '1',
                                  text: expert.location
                                }
                              });
                              router.push(routes.destination(expert.location.toLowerCase()));
                            }}
                          >
                            {expert.location}{' '}
                          </span>
                        </p>

                        <p>BEST EXPERIENCE PLANNED:</p>
                        <p>{expert.experienceDescription}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmblaCarousel;
