import React, { useLayoutEffect, useEffect, useState } from 'react';

// Utils
import { getDestinations } from '@utils/getDestinations';
import { getPageConfigServerSideProps } from '@utils/config';
import Logger from '@utils/logger';
// Constants
import apiUrls from '@constants/api-urls';
import configHomePage from '@constants/pagesConfigs/homepage';
// Components
import WebflowPage from '@components/WebflowPage/WebflowPage';
import { HowItWorks as HowItWorksV0 } from '@components/Homepage/HowItWorks';
import { HowItWorks as HowItWorksV1 } from '@components/Homepage/HowItWorksV1';
import HomepageHero from '@components/Homepage/Hero/Hero';
import HomepageQaSection from '@components/Homepage/QASection/QASection';
import PressBarSection from '@components/Homepage/PressBar/PressBar';
import PageHead from '@components/shared/PageHead/PageHead';
import { FeelGoodTrips } from '@components/Homepage/FeelGoodTrips';
import { Itineraries } from '@components/Homepage/Itineraries';
import GoogleReviews from '@components/Homepage/GoogleReviews/GoogleReviews';
import LocalExperts from '@components/LocalExperts/LocalExperts';
import { googlePlacesApiKey } from '@constants/constants';
// Hooks
import useMonarch from '@hooks/useMonarch';

const HomePage = ({ pageConfig, destinationDetails, reviews }) => {
  const [shouldShowItineraries, setShouldShowItineraries] = useState(false);
  const [shouldShowHowItWorksV1, setShouldShowHowItWorksV1] = useState(false);
  const [useLocalExpertV1, setUseLocalExpertV1] = useState('loading');
  const { monarch } = useMonarch();

  useLayoutEffect(() => {
    const webflow = document.createElement('script');

    setTimeout(() => {
      webflow.src = '/webflow/js/webflow.js';
      document.body.appendChild(webflow);
    }, 500);

    return () => {
      try {
        delete window.Webflow;
        document.body.removeChild(webflow);
      } catch (e) {
        Logger.error(e);
      }
    };
  }, []);

  useEffect(() => {
    monarch({
      rid: '28b66d5b-4521-42bd-90b8-7129d007b3db',
      callback: function (err, result) {
        if (err) {
          return Logger.error('error during monarch callback', err);
        }
        setShouldShowItineraries(result?.itinerariesSection);
      }
    });

    monarch({
      rid: '5b1844ec-cf24-4ece-9303-9e460a431a3b',
      callback: function (err, result) {
        if (err) {
          return Logger.error('error during monarch callback', err);
        }

        const { homePageLocalExpertV1 = false } = result;
        setUseLocalExpertV1(true);
      }
    });

    monarch(
      {
        rid: '77bc863a-72d9-4761-a97d-d674e4758111',
        callback: function (err, result) {
          if (err) {
            return Logger.error('error during monarch callback', err);
          }
          setShouldShowHowItWorksV1(result?.howItWorksSection);
        }
      },
      { timeout: 1000 }
    );
  }, []);

  return (
    <WebflowPage className="homepage" pageId="5f68df773d457c00bd9bc927" isShowFooterQA={false}>
      <PageHead pageConfig={pageConfig} defaultConfig={configHomePage}>
        {process.env.NODE_ENV === 'production' && (
          <script
            dangerouslySetInnerHTML={{
              __html: `window['_fs_debug'] = false; window['_fs_host'] = 'fullstory.com'; window['_fs_script'] = 'edge.fullstory.com/s/fs.js'; window['_fs_org'] = '18DCW8'; window['_fs_namespace'] = 'FS'; (function(m,n,e,t,l,o,g,y){ if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;} g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[]; o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script; y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y); g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)}; g.anonymize=function(){g.identify(!!0)}; g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)}; g.log = function(a,b){g("log",[a,b])}; g.consent=function(a){g("consent",!arguments.length||a)}; g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)}; g.clearUserCookie=function(){}; g.setVars=function(n, p){g('setVars',[n,p]);}; g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y]; if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)}; g._v="1.3.0"; })(window,document,window['_fs_namespace'],'script','user');`
            }}
          />
        )}
      </PageHead>
      <HomepageHero />
      <PressBarSection />
      <div className="section-change-travel">
        <div className="frame-container">
          <div className="introduction-box">
            <img
              src="/webflow/images/lanterns.png"
              loading="lazy"
              sizes="100vw"
              srcSet="/webflow/images/lanterns-p-500.png 500w, /webflow/images/lanterns-p-800.png 800w, /webflow/images/lanterns-p-1080.png 1080w, /webflow/images/lanterns.png 2282w"
              alt=""
              className="intro-image"
            />
            <div className="page-container go-front">
              <h3>
                Travel is changing.
                <br />
                Join the change.
              </h3>
              <h5 className="no-br-mobile">
                We are committed to finding a better solution <br />
                for travelers and the destinations they visit.
              </h5>
            </div>
          </div>
        </div>
      </div>

      {useLocalExpertV1 !== 'loading' ? (
        useLocalExpertV1 ? (
          <div className="section-local-experts" id="local-experts">
            {' '}
            <LocalExperts />{' '}
          </div>
        ) : (
          <div className="section-ups">
            <div className="page-container center">
              <h2 className="h2-center no-br-mobile">No one does it like our local experts</h2>
              <h4 className="h-center no-br-mobile txt-black header-unbold">
                Meet our on-the-ground heroes. They know the secret spots,&nbsp;
                <br />
                the must-do adventures, and have the connections to make anything happen.
              </h4>
              <div data-w-id="f75989f6-f2ba-99f9-94d8-bef7186c2ab2" className="operator-vs-expert w-clearfix">
                <div className="operator">
                  <div data-w-id="67e16e80-4998-b0cb-a602-9d9cb44e7915" className="operator-message">
                    <h4>
                      Why trust Gary in Florida to plan your trip to <span className="span-mongolia">Mongolia</span>{' '}
                      <span data-w-id="0f886757-5b79-628a-ee4c-3d9ff7f70b84" className="span-south-africa">
                        South Africa
                      </span>
                      <span data-w-id="24e414d0-3e47-57f3-159a-9b350b2dc927" className="span-argentina">
                        Argentina
                      </span>
                      <span data-w-id="3c4ca908-9e3c-4c5d-96b3-eca2b404157c" className="span-portugal">
                        Portugal
                      </span>
                      ?
                    </h4>
                    <img
                      src="/webflow/images/bubble-triangle-right.svg"
                      loading="lazy"
                      alt=""
                      className="bubble-triangle"
                    />
                  </div>
                  <div className="operator-image-wrapper">
                    <img
                      src="/webflow/images/operator1.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 44vw, (max-width: 1279px) 39vw, (max-width: 1439px) 40vw, (max-width: 1919px) 42vw, 709.546875px"
                      srcSet="/webflow/images/operator1-p-500.png 500w, /webflow/images/operator1.png 1200w"
                      alt=""
                      className="image-operator"
                    />
                    <img
                      src="/webflow/images/operator1-mobile.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 87vw, 100vw"
                      srcSet="/webflow/images/operator1-mobile-p-500.png 500w, /webflow/images/operator1-mobile.png 732w"
                      alt=""
                      className="image-operator-mobile"
                    />
                  </div>
                </div>
                <div className="expert">
                  <div data-w-id="26379456-8397-92c4-b24a-9492e74fd7af" className="expert-message">
                    <h4 data-w-id="b8ab1dfb-2cfb-7390-0dc5-f2cbcf596f96" className="text-magenta expert-name lisbon">
                      <strong>When Tiago in Lisbon knows best.</strong>
                    </h4>
                    <h4 data-w-id="45fadf56-3665-2e1e-9301-d9322c4b2d0f" className="text-magenta expert-name argentina">
                      <strong>When Lucas in Buenos Aires knows best.</strong>
                    </h4>
                    <h4
                      data-w-id="9e19977e-a582-c25f-5399-64cb9cf02fc6"
                      className="text-magenta expert-name south-africa"
                    >
                      <strong>When Angela in Johannesburg knows best.</strong>
                    </h4>
                    <h4 className="text-magenta mongolia">
                      <strong>When Zoloo in Ulaanbaatar knows best.</strong>
                    </h4>
                    <img
                      src="/webflow/images/bubble-triangle-left.svg"
                      loading="lazy"
                      alt=""
                      className="bubble-triangle left"
                    />
                  </div>
                  <div className="expert-image-wrapper">
                    <div data-w-id="176b5825-8d46-76d0-fbc9-246b360bc098" className="desktop-expert-wrapper">
                      <img
                        src="/webflow/images/local-expert-portugal.png"
                        loading="lazy"
                        data-w-id="971c0719-75f1-7756-df76-f9eef6230e5a"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 44vw, (max-width: 1279px) 39vw, (max-width: 1439px) 40vw, (max-width: 1919px) 42vw, 709.546875px"
                        srcSet="/webflow/images/local-expert-portugal-p-500.png 500w, /webflow/images/local-expert-portugal.png 1200w"
                        alt=""
                        className="image-agent portugal"
                      />
                      <img
                        src="/webflow/images/local-expert-argentina.png"
                        loading="lazy"
                        data-w-id="8cbf2f2c-11ea-2cbf-a83a-eef2b2ab76e9"
                        alt=""
                        className="image-agent argentina"
                      />
                      <img
                        src="/webflow/images/local-expert-south-africa.png"
                        loading="lazy"
                        data-w-id="6922f59c-44b4-2f7f-8538-8235fbdface0"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 44vw, (max-width: 1279px) 39vw, (max-width: 1439px) 40vw, (max-width: 1919px) 42vw, 709.546875px"
                        srcSet="/webflow/images/local-expert-south-africa-p-500.png 500w, /webflow/images/local-expert-south-africa.png 1200w"
                        alt=""
                        className="image-agent south-africa"
                      />
                      <img
                        src="/webflow/images/local-expert-mongolia.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 44vw, (max-width: 1279px) 39vw, (max-width: 1439px) 40vw, (max-width: 1919px) 42vw, 709.546875px"
                        srcSet="/webflow/images/local-expert-mongolia-p-500.png 500w, /webflow/images/local-expert-mongolia.png 1200w"
                        alt=""
                        className="image-agent mongolia"
                      />
                    </div>
                    <div className="mobile-expert-wrapper">
                      <img
                        src="/webflow/images/local-expert-portugal-mobile.png"
                        loading="lazy"
                        data-w-id="d761d955-a3e2-5c15-2528-0d5b1d26660b"
                        alt=""
                        className="image-agent-mobile portugal"
                      />
                      <img
                        src="/webflow/images/local-expert-argentina-mobile.png"
                        loading="lazy"
                        data-w-id="5f57a0c4-3bd5-3fc9-07c5-aa6b78751369"
                        alt=""
                        className="image-agent-mobile argentina"
                      />
                      <img
                        src="/webflow/images/local-expert-south-africa-mobile.png"
                        loading="lazy"
                        data-w-id="c927bd8d-4ed6-37f0-54c4-91405b926d86"
                        alt=""
                        className="image-agent-mobile south-africa"
                      />
                      <img
                        src="/webflow/images/local-expert-mongolia-mobile.png"
                        loading="lazy"
                        alt=""
                        className="image-agent-mobile mongolia"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : null}

      {shouldShowItineraries && (
        <div className="section-itinerary" id="itinerary">
          <Itineraries />
        </div>
      )}
      <div className="section-reviews" id="reviews">
        <GoogleReviews reviews={reviews} />
      </div>
      {shouldShowHowItWorksV1 ? (
        <div className="section-how-it-works section-how-it-works--v1" id="how-it-works">
          <HowItWorksV1 />
        </div>
      ) : (
        <div className="section-how-it-works" id="how-it-works">
          <HowItWorksV0 destinationDetails={destinationDetails} />
        </div>
      )}
      <div className="in-page-hero">
        <div className="full-page-container">
          <div className="commitments-inpage-hero">
            <div className="inpage-hero-content center jordan-hero">
              <h2 className="inpage-hero-title no-br-mobile">
                When a destination thrives, <br />
                so does your experience there.
              </h2>
              <h4 className="inpage-hero-text no-br-mobile">
                Elsewhere was born from the idea that travelers <br />
                and the destinations they visit deserve better.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="section-why-it-works" id="why-it-works">
        <FeelGoodTrips />
      </div>
      <div className="section-testimonial">
        <div className="full-page-container full-image">
          <div className="testimonial-footer-container home">
            <div className="testimonial-columns">
              <div className="testimonial-column left">
                <h2 className="text-white">
                  Try a new way to travel. <br />
                  The best trip of your life <br />
                  starts here.
                </h2>
              </div>
              <div className="testimonial-column right home">
                <div className="testimonial-quote-wrapper">
                  <h4 className="no-margin">
                    <strong>
                      &quot;This trip exceeded my wildest expectations. My son and I were able to learn the truth of our
                      history as well as get deep into local culture and customs, both past and present. Thanks to our
                      guides who made this trip feel like a wonderful family reunion. This was a truly life changing
                      trip. I’m already planning to bring my extended family back.&quot;
                    </strong>
                  </h4>
                  <div className="testimonial-info">Belinda &amp; fam, Black Star Square in Accra</div>
                </div>
                <div className="testimonial-host-wrapper">
                  <div className="testimonial-host-img wrapper">
                    <div className="host-img-container">
                      <img
                        src="/webflow/images/agent-patience.jpeg"
                        loading="lazy"
                        sizes="(max-width: 479px) 300px, (max-width: 991px) 300px, 300px"
                        srcSet="webflow/images/agent-patience.jpeg 1000w"
                        alt=""
                        className="host-img"
                      />
                    </div>
                  </div>
                  <div className="host-info">
                    <div className="text-white-mobile _50opacity">Trip crafted by</div>
                    <div className="testimonial-agent-area">
                      <h5 className="host-name">Patience, local expert in Ghana</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="testimonial-gradient home" />
          </div>
        </div>
      </div>
      <HomepageQaSection />
    </WebflowPage>
  );
};

export async function getServerSideProps() {
  const pageData = await getPageConfigServerSideProps(apiUrls.pageConfigs.homepage);
  // destination info for pricing widget
  const destinationDetails = await getDestinations();
  pageData.props.destinationDetails = destinationDetails;

  // google places api for reviews and ratings data
  if (!googlePlacesApiKey) {
    Logger.error('Missing GOOGLE_PLACES_API_KEY in environment variables');
  }

  await fetch(
    `https://places.googleapis.com/v1/places/ChIJY4Kt7Vpx-4gRcp2urC8fCag?fields=id,displayName,rating,userRatingCount&key=${googlePlacesApiKey}`
  )
    .then((response) => response.json())
    .then((data) => (pageData.props.reviews = data))
    .catch((error) => Logger.error('Error fetching google places data', error));

  return { ...pageData };
}

export default HomePage;
