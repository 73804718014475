import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { Fade, Button as HiWButton } from '@mui/material';

import styles from '../Hero.module.scss';

import routes from '@constants/routes';
import Select from '@components/shared/Form/Select/Select';
import Icon from '@components/shared/Icon/Icon';
import { elementClicked } from '@utils/sendEvent';

const Search = ({ regionsStore }) => {
  const router = useRouter();
  const handleSelectChange = ({ value }) => {
    router.push(routes.destinations.details(value.value));
  };

  return (
    <Fade in={true} timeout={1000}>
      <div className="hero-search v0">
        <div className="form-block w-form">
          <form
            className="hero-form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div
              onClick={() =>
                elementClicked({
                  actionOutcome: 'SEARCH',
                  outboundUrl: '',
                  webElement: {
                    elementType: 'Button',
                    location: 'Homepage',
                    name: 'Hero - Search Bar',
                    position: '0',
                    text: 'Where do you want to go?'
                  }
                })
              }
            >
              <Select
                className="homepage-hero__select hero-input w-input"
                placeholder="Where do you want to go?"
                aria-label="Search destination"
                options={regionsStore.groupedSelectOptions('slug')}
                components={{
                  DropdownIndicator: () => null
                }}
                onChange={handleSelectChange}
              />
            </div>
            <button type="submit" title="Find destination" className="hero-submit w-button" />
          </form>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          className={classnames(styles['how-it-works-button'])}
        >
          <HiWButton
            variant="outlined"
            size="large"
            href="/#how-it-works"
            sx={{
              mt: { xs: 1, md: 3 },
              minWidth: 'auto',
              color: 'white !important',
              pl: '12px',
              pr: '20px',
              height: 'auto',
              textDecoration: 'none !important',
              fontSize: '14px',
              backgroundColor: '#342F4180'
            }}
            onClick={() => {
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'button',
                  location: 'homepage',
                  name: 'How it works',
                  position: '1',
                  text: 'How it works'
                }
              });
            }}
          >
            <Icon icon="info" style={{ marginRight: '10px' }} size={22} /> How it works
          </HiWButton>
        </div>
      </div>
    </Fade>
  );
};

export default Search;
