import styles from './YourTimeIsPrecious.module.scss';

export function YourTimeIsPrecious() {
  return (
    <div className={styles['your-time-is-precious']}>
      <div className={styles['your-time-is-precious__container']}>
        <div className={styles['your-time-is-precious__grid']}>
          <div className={styles['your-time-is-precious__grid__headers']}>
            <h2>Your time is precious.</h2>
            <h3>The average international trip takes a minimum of 40 hours of research.</h3>
          </div>
          <h4 className={styles['your-time-is-precious__grid__body']}>
            It’s our job to cut through the noise with tailored recommendations based on our boots-on-the-ground
            knowledge and personal connections. We’ve vetted and tested, so you don’t have to.
          </h4>
        </div>
      </div>
    </div>
  );
}
