import { useState, useEffect, useMemo, useRef } from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import styles from './Itineraries.module.scss';

import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import theme from '@styles/theme';
import { ITINERARIES } from '@constants/homepage/Itineraries';
import Icon from '@components/shared/Icon/Icon';
import useSwipe from '@hooks/useSwipe';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function Itineraries() {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const { setNodeRef, isVisible } = useIntersectionObserver(
    {
      threshold: isExtraLarge ? 0.9 : 0.5
    },
    false
  );

  const [previousIndex, setPreviousIndex] = useState(ITINERARIES.length - 1);
  const [index, setIndex] = useState(0);

  const previousItinerary = useMemo(() => ITINERARIES[previousIndex], [previousIndex]);
  const activeItinerary = useMemo(() => ITINERARIES[index], [index]);

  const timerRef = useRef<NodeJS.Timer>();

  const updateIndex = () => {
    setIndex((i) => {
      const newIndex = (i + 1) % ITINERARIES.length;
      setPreviousIndex(i);
      return newIndex;
    });
  };

  useEffect(() => {
    if (isVisible) {
      timerRef.current = setInterval(updateIndex, 6900);
      elementViewed({
        webElement: {
          elementType: 'Link',
          location: 'Homepage',
          name: 'Itinerary Image',
          position: '1',
          text: `${activeItinerary.itinerary.traveler}'s itinerary to ${activeItinerary.itinerary.destination}`
        }
      });
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isVisible]);

  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipedLeft: () => {
      setPreviousIndex(index);
      setIndex(index === ITINERARIES.length - 1 ? 0 : index + 1);
    },
    onSwipedRight: () => {
      setPreviousIndex(index);
      setIndex(index === 0 ? ITINERARIES.length - 1 : index - 1);
    }
  });

  return (
    <div
      className="in-page-hero"
      onPointerDown={() => {
        //Stop auto advance on user interaction
        clearInterval(timerRef.current);
      }}
      onTouchMove={(e: any) => {
        onTouchMove(e);
      }}
      onTouchStart={(e: any) => {
        onTouchStart(e);
      }}
      onTouchEnd={() => {
        onTouchEnd();
      }}
    >
      <div className="full-page-container" ref={setNodeRef}>
        <div className={classnames(styles['itineraries'], 'itineraries')}>
          <div
            className="itineraries__in-page-hero itineraries__in-page-hero--previous"
            style={{
              backgroundImage: isSmall
                ? `linear-gradient(0deg, #342F4100 0%, #2b185f6e 100%), url(${previousItinerary.backgroundImg.mobile})`
                : `linear-gradient(249deg, #342F4100 0%, #2b185f6e 100%), url(${previousItinerary.backgroundImg.desktop})`
            }}
            key={previousIndex}
          ></div>
          <div
            className="itineraries__in-page-hero itineraries__in-page-hero--active"
            style={{
              backgroundImage: isSmall
                ? `linear-gradient(0deg, #342F4100 0%, #2b185f6e 100%), url(${activeItinerary.backgroundImg.mobile})`
                : `linear-gradient(249deg, #342F4100 0%, #2b185f6e 100%), url(${activeItinerary.backgroundImg.desktop})`
            }}
            key={index}
          ></div>
          <div className="itineraries__content">
            <div className="itineraries__text">
              <h5>Trips we can&rsquo;t stop thinking about</h5>
              <h2>
                Our edit of bold and inspiring trips&nbsp;
                {isLarge && <br />}
                we&rsquo;ve curated for travelers like you.
              </h2>
              <p>
                No two trips are the same. Your trip will be built from the ground up,&nbsp;
                {isLarge && <br />}
                handcrafted and thoughtfully personalized to you.
              </p>
              <div className="itineraries__nav">
                <a
                  href={activeItinerary.itinerary.url}
                  onClick={() => {
                    elementClicked({
                      actionOutcome: 'INTERNALLINK',
                      outboundUrl: `${activeItinerary.itinerary.url}`,
                      webElement: {
                        elementType: 'Link',
                        location: 'Homepage',
                        name: 'Itinerary CTA',
                        position: '0',
                        text: `${activeItinerary.itinerary.traveler}'s itinerary to ${activeItinerary.itinerary.destination}`
                      }
                    });
                  }}
                  className="itineraries__nav-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="itineraries__nav-button-text">
                    See {activeItinerary.itinerary.traveler}&rsquo;s itinerary to{' '}
                    {activeItinerary.itinerary.destination}
                  </span>
                  <Icon icon="icon_24_open" size={25} />
                </a>
                <div>
                  {ITINERARIES.map((_, i) => (
                    <button
                      key={`itinerary-${i}`}
                      className={classnames('itineraries__nav-dot', {
                        'itineraries__nav-dot--active': i === index
                      })}
                      onClick={() => {
                        if (i !== index) {
                          setPreviousIndex(index);
                          setIndex(i);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <a
              className="itineraries__frame-wrapper"
              href={activeItinerary.itinerary.url}
              onClick={() => {
                elementClicked({
                  actionOutcome: 'INTERNALLINK',
                  outboundUrl: `${activeItinerary.itinerary.url}`,
                  webElement: {
                    elementType: 'Link',
                    location: 'Homepage',
                    name: 'Itinerary Image',
                    position: '1',
                    text: `${activeItinerary.itinerary.traveler}'s itinerary to ${activeItinerary.itinerary.destination}`
                  }
                });
              }}
              target="_blank"
              rel="noreferrer"
            >
              {ITINERARIES.map((itinerary, i) => (
                <div
                  className={classnames(
                    'itineraries__frame',
                    { 'itineraries__frame--active': i === index },
                    { 'itineraries__frame--previous': i === previousIndex }
                  )}
                  key={`itinerary-${i}`}
                >
                  <div
                    className={
                      isVisible && (i === index || i === previousIndex) ? `itineraries__frame-scroll--${i}` : ''
                    }
                  >
                    <Image src={itinerary.scrollImg.url} alt="ribbon" width={822} height={itinerary.scrollImg.height} />
                  </div>
                </div>
              ))}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
