import React from 'react';

export function IconClick({ width = 32, height = 32, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-click" transform="translate(-338 -3442)">
        <rect
          id="Rectangle_1152"
          data-name="Rectangle 1152"
          width="32"
          height="32"
          transform="translate(338 3442)"
          fill="none"
        />
        <g id="Group_2399" data-name="Group 2399" transform="translate(340.138 3444.138)">
          <path
            id="Path_1696"
            data-name="Path 1696"
            d="M9,9l7.374,17.7,2.654-7.668L26.7,16.374Z"
            transform="translate(1.028 1.028)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1697"
            data-name="Path 1697"
            d="M7.2,2.2,8.38,6.477"
            transform="translate(0.174 -2.2)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1698"
            data-name="Path 1698"
            d="M6.477,8.38,2.2,7.2"
            transform="translate(-2.2 0.174)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1699"
            data-name="Path 1699"
            d="M14.949,4.1,12,6.9"
            transform="translate(2.452 -1.298)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_1700"
            data-name="Path 1700"
            d="M6.9,12,4.1,14.949"
            transform="translate(-1.298 2.452)"
            fill="none"
            stroke="#5b5866"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
