import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { pageTitleWithPrefix } from '@utils/meta';

const PageHead = ({ pageConfig, defaultConfig, children, rightSide }) => {
  const pageTitle = pageConfig.meta_title || pageTitleWithPrefix(defaultConfig.meta_title, rightSide);

  const metaDescription = pageConfig.meta_description || defaultConfig.meta_description;

  const metaKeywords = pageConfig.meta_keywords || defaultConfig.meta_keywords;

  const pageOgTitle = pageConfig.og_title || pageTitleWithPrefix(defaultConfig.og_title, rightSide);

  const pageOgDescription = pageConfig.og_description || defaultConfig.og_description;

  const pageTwitterTitle = pageConfig.twitter_title || pageTitleWithPrefix(defaultConfig.twitter_title, rightSide);

  const metaTwitterDescription = pageConfig.twitter_description || defaultConfig.twitter_description;

  const ogURL = pageConfig.og_url || defaultConfig.og_url;

  const ogImageAbsoluteUrl = pageConfig.og_image || defaultConfig.og_image;

  const twitterImageAbsoluteUrl = pageConfig.twitter_image || defaultConfig.twitter_image;

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      <meta content={metaDescription} name="description" />
      {metaKeywords && <meta content={metaDescription} name="keywords" />}
      <meta content={pageOgTitle} property="og:title" />
      <meta content={pageOgDescription} property="og:description" />
      <meta content={pageTwitterTitle} property="twitter:title" />
      <meta content={metaTwitterDescription} property="twitter:description" />
      <meta property="og:type" content="website" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      {ogURL && <meta content={ogURL} property="og:url" />}
      {ogImageAbsoluteUrl && <meta content={ogImageAbsoluteUrl} property="og:image" />}
      {twitterImageAbsoluteUrl && <meta content={twitterImageAbsoluteUrl} property="twitter:image" />}
      {children}
    </Head>
  );
};

PageHead.propTypes = {
  pageConfig: PropTypes.object,
  defaultConfig: PropTypes.object,
  children: PropTypes.any,
  rightSide: PropTypes.bool
};

export default PageHead;
