import React from 'react';
import Image from 'next/image';
import { useMediaQuery } from '@mui/material';

import styles from './FeelGoodTrips.module.scss';

import YouCircle from '@public/img/homepage/feelGoodTrips/you-circle.png';
import YourDestinationCircle from '@public/img/homepage/feelGoodTrips/your-destination-circle.png';
import theme from '@styles/theme';
import FeelGoodTripsRibbon from '@public/img/homepage/feelGoodTrips/trips-you-can-feel-good-vertical.png';

export function FeelGoodTrips() {
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className="full-page-container overflow-visible">
      <div className="page-container small">
        <div className={styles['feel-good-trips']}>
          <div className="feel-good-trips__intro">
            <h2 className="feel-good-trips__intro-title">Trips you can feel good about</h2>
            <h4 className="feel-good-trips__intro-text">
              We believe that travel should be built on local insight,
              {isSmall ? ' ' : <br />}
              created and sold directly from the destination itself.
            </h4>
          </div>

          <div className="feel-good-trips__body">
            {!isSmall && (
              <div className="feel-good-trips__ribbon">
                <p className="feel-good-trips__ribbon-text-top">You</p>
                <p className="feel-good-trips__ribbon-text-bottom">
                  Your
                  <br />
                  destination
                </p>
                <Image
                  className="feel-good-trips__ribbon-img"
                  src={FeelGoodTripsRibbon.src}
                  alt="ribbon"
                  width={293}
                  height={592}
                />
              </div>
            )}
            <div className="feel-good-trips__body-text">
              <div className="feel-good-trips__body-column">
                {isSmall && (
                  <div className="feel-good-trips__circle">
                    <p className="feel-good-trips__circle-text">You</p>
                    <Image src={YouCircle.src} alt="you circle" width={180} height={180} />
                  </div>
                )}
                <h4>
                  By traveling with Elsewhere, we guarantee inside access
                  {isSmall ? ' ' : <br />}
                  to extraordinary trips
                </h4>
                <p>
                  Our experts are true locals - they know the backroads, the hidden gems, and all the little details
                  that make or break a trip. Imagine waking up in a Spanish colonial estancia in Uruguay’s cowboy
                  country with the embers of last night’s campfire still smoking, navigating Tokyo’s intricate canals
                  with a native Edokko guide, or visiting an eagle hatchery in the middle of the Mongolian steppe.
                </p>
              </div>
              <div className="feel-good-trips__body-column">
                {isSmall && (
                  <div className="feel-good-trips__circle">
                    <p className="feel-good-trips__circle-text">Your destination</p>
                    <Image src={YourDestinationCircle.src} alt="your destination circle" width={180} height={180} />
                  </div>
                )}
                <h4>
                  By traveling with Elsewhere, you are supporting the protection and preservation of your host country
                </h4>
                <p>
                  We partner with the best of the best, which means people who care about their planet, their
                  communities, and their wildlife. By planning your trip with a local, you’re booking with someone who
                  is dedicated to showing you what they love about their country, while also preserving it for
                  generations to come.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
