import { useState, Fragment } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';

import styles from './Pricing.module.scss';

import ChartElsewhereModel from '@public/img/homepage/chart-elsewhere-model.svg';
import ChartIndustryModel from '@public/img/homepage/chart-industry-model.svg';
import { FAQ_PRICING } from '@constants/homepage/HowItWorks';
import GlobalPriceWidget from '@components/shared/GlobalPriceWidget/GlobalPriceWidget';
import routes from '@constants/routes';
import Icon from '@components/shared/Icon/Icon';
import Button from '@components/shared/Button/Button';
import theme from '@styles/theme';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function Pricing({ destinationDetails }) {
  const [openDropdown, setOpenDropdown] = useState(0);
  const [isElsewhereModel, setIsElsewhereModel] = useState(true);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const handleElsewhereModel = () => {
    setIsElsewhereModel(true);
    elementClicked({
      actionOutcome: 'POP',
      outboundUrl: 'null',
      webElement: {
        elementType: 'Tab',
        location: 'Homepage',
        name: 'Where your money goes',
        position: 'HOWITWORKS',
        text: 'Elsewhere model'
      }
    });
    elementViewed({
      webElement: {
        elementType: 'Tab',
        location: 'Homepage',
        name: 'Where your money goes',
        position: 'HOWITWORKS',
        text: 'Elsewhere model'
      }
    });
  };

  const handleIndustryModel = () => {
    setIsElsewhereModel(false);
    elementClicked({
      actionOutcome: 'POP',
      outboundUrl: 'null',
      webElement: {
        elementType: 'Tab',
        location: 'Homepage',
        name: 'Where your money goes',
        position: 'HOWITWORKS',
        text: 'Industry model'
      }
    });
    elementViewed({
      webElement: {
        elementType: 'Tab',
        location: 'Homepage',
        name: 'Where your money goes',
        position: 'HOWITWORKS',
        text: 'Industry model'
      }
    });
  };

  return (
    <div className={styles['pricing-container']}>
      <div className={styles['destination-price-widget']}>
        <GlobalPriceWidget destinationDetails={destinationDetails} />
      </div>

      <div className={classnames(styles['text-container-wrapper'], styles['where-your-money-goes'])}>
        <div className={styles['text-container-left']}>
          <h2>Where your money goes</h2>
          {isDesktop && <h3>Radical transparency</h3>}
          <div className="where-your-money-goes__toolbar">
            <button
              type="button"
              className={classnames('where-your-money-goes__toolbar-button', {
                'where-your-money-goes__toolbar-button--active': isElsewhereModel
              })}
              onClick={handleElsewhereModel}
            >
              <div
                className={classnames('where-your-money-goes__toolbar-inner-button', {
                  'where-your-money-goes__toolbar-inner-button--active': isElsewhereModel
                })}
              >
                Elsewhere model
              </div>
              <div className="where-your-money-goes__toolbar-inner-arrow">
                <Icon
                  icon="arrow-right-gradient"
                  size={16}
                  className={classnames('where-your-money-goes__toolbar-inner-arrow-icon', {
                    'where-your-money-goes__toolbar-inner-arrow-icon--active': isElsewhereModel
                  })}
                />
              </div>
            </button>
            <button
              type="button"
              className={classnames('where-your-money-goes__toolbar-button', {
                'where-your-money-goes__toolbar-button--active': !isElsewhereModel
              })}
              onClick={handleIndustryModel}
            >
              <div
                className={classnames('where-your-money-goes__toolbar-inner-button', {
                  'where-your-money-goes__toolbar-inner-button--active': !isElsewhereModel
                })}
              >
                Industry model
              </div>
              <div className="where-your-money-goes__toolbar-inner-arrow">
                <Icon
                  icon="arrow-right-gradient"
                  size={16}
                  className={classnames('where-your-money-goes__toolbar-inner-arrow-icon', {
                    'where-your-money-goes__toolbar-inner-arrow-icon--active': !isElsewhereModel
                  })}
                />
              </div>
            </button>
          </div>
        </div>
        <div className={styles['text-container-right']}>
          <div className="where-your-money-goes__content">
            <div className="where-your-money-goes__content-container">
              <div className="where-your-money-goes__chart">
                <div className="where-your-money-goes__chart-img">
                  <Image
                    src={isElsewhereModel ? ChartElsewhereModel.src : ChartIndustryModel.src}
                    alt={isElsewhereModel ? 'elsewhere model' : 'industry model'}
                    height={isDesktop ? 316 : 200}
                    width={isDesktop ? 316 : 200}
                  />
                  <p className="where-your-money-goes__chart-label">
                    {isElsewhereModel ? 'Elsewhere model' : 'Industry model'}
                  </p>
                </div>
              </div>
              <div className="where-your-money-goes__text">
                <div className="where-your-money-goes__labels">
                  <div className="where-your-money-goes__labels-columns">
                    <h3 className="where-your-money-goes__labels-header-blue">{isElsewhereModel ? '84%' : '30%'}</h3>
                    <p>
                      {isElsewhereModel
                        ? 'Investment in quality suppliers and projects that support the destination'
                        : 'Shocking, but true. Typically only 10-30% of your tourism dollars stay in-destination'}
                    </p>
                  </div>
                  <div className="where-your-money-goes__labels-columns">
                    <h3 className="where-your-money-goes__labels-header-purple">{isElsewhereModel ? '16%' : '70%'}</h3>
                    <p>
                      {isElsewhereModel
                        ? 'Keeps our website running and includes all our environmental contributions (carbon offset, reforestation, and ocean plastic removal)'
                        : 'In the pockets of tour giants and booking aggregators, rarely based in the visiting country'}
                    </p>
                  </div>
                </div>
                {!isElsewhereModel && (
                  <div>
                    <hr />
                    <div className="where-your-money-goes__industry-note">
                      <h4>You have the power to change the way travel is done.</h4>
                      <p>
                        Demand better practices by supporting companies that are focused on doing good. Visit{' '}
                        <Link href={routes.ourCommitments}>
                          <a>
                            <span>Our Commitments</span>
                          </a>
                        </Link>{' '}
                        for a guideline on what to look for when booking travel.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['text-container-wrapper']}>
        <div className={styles['text-container-left']}>
          <h2>Frequently asked questions</h2>
        </div>
        <div className={styles['text-container-right']}>
          {FAQ_PRICING.map((q, i) => {
            const { h3, p } = q;
            return (
              <button
                className={styles['dropdown']}
                type="button"
                key={h3}
                onClick={() => {
                  if (openDropdown === i) {
                    setOpenDropdown(-1);
                  } else {
                    setOpenDropdown(i);
                  }

                  elementClicked({
                    actionOutcome: 'EXPAND',
                    outboundUrl: 'null',
                    webElement: {
                      elementType: 'Button',
                      location: 'Homepage',
                      name: `FAQ - ${i + 1}`,
                      position: 'HOWITWORKS-PRICING',
                      text: h3
                    }
                  });
                  elementViewed({
                    webElement: {
                      elementType: 'Button',
                      location: 'Homepage',
                      name: `FAQ - ${i + 1}`,
                      position: 'HOWITWORKS-PRICING',
                      text: h3
                    }
                  });
                }}
              >
                <div className={styles['dropdown-title']}>
                  <h4>{h3}</h4>
                  <Icon
                    icon="arrow-up"
                    size={16}
                    style={openDropdown === i ? { transform: 'rotate(0)' } : { transform: 'rotate(180deg)' }}
                  />
                </div>
                <div
                  className={classnames(
                    styles['dropdown-content'],
                    openDropdown === i ? styles['dropdown-open'] : styles['dropdown-closed']
                  )}
                >
                  <p>
                    {p.map((text) => (
                      <Fragment key={text}>
                        {text}
                        <br />
                        <br />
                      </Fragment>
                    ))}
                  </p>
                </div>
                {i === 0 ? <hr /> : null}
              </button>
            );
          })}
          <Button
            variant="primary"
            className={styles['craft-trip']}
            href="/request-to-travel"
            onClick={() => {
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'Button',
                  location: 'Homepage',
                  name: 'CTA',
                  position: 'HOWITWORKS-PRICING',
                  text: 'craft your trip CTA'
                }
              });
            }}
          >
            Craft your trip
          </Button>
        </div>
      </div>
    </div>
  );
}
