import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import pluralize from 'pluralize';

import styles from './GlobalPriceWidget.module.scss';

import Icon from '@components/shared/Icon/Icon';
import Slider from '@components/shared/Slider/Slider';
import InclusionsExclusions from '@components/RequestForm/Budget/InclusionsExclusions';
import { elsewhere } from '@styles/theme';
import helpers from '@utils/helpers';
import { getNewCost } from '@utils/destinations';

const { formatNumber, formatPriceStr } = helpers;

const GlobalPriceWidget = ({ destinationDetails }) => {
  const [filteredDestinations, setFilteredDestinations] = useState([]);
  const [destinationLookup, setDestinationLookup] = useState({});
  const [selectedDestination, setSelectedDestination] = useState({
    maximumCost: 1000,
    minimumCost: 260,
    name: 'Argentina'
  });
  const [travelers, setTravelers] = useState(2);
  const [days, setDays] = useState(6);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [price, setPrice] = useState(0);
  const [mark1, setMark1] = useState(0);
  const [mark2, setMark2] = useState(0);

  useEffect(() => {
    const newFilteredDestinations = destinationDetails
      .filter((destination) => destination.minimumCost && destination.maximumCost && destination.status === 'active')
      .sort((a, b) => a.name.localeCompare(b.name));

    setFilteredDestinations(newFilteredDestinations);
    setSelectedDestination(newFilteredDestinations[0]);

    const newDestinationLookup = newFilteredDestinations.reduce((lookup, destination) => {
      lookup[destination.name] = destination;
      return lookup;
    }, {});
    setDestinationLookup(newDestinationLookup);
  }, [destinationDetails]);

  useEffect(() => {
    if (selectedDestination) {
      const newMinPrice = getNewCost(selectedDestination.minimumCost, travelers, days);
      const newMaxPrice = getNewCost(selectedDestination.maximumCost, travelers, days);
      setMinPrice(newMinPrice);
      setMaxPrice(newMaxPrice);
      setPrice((newMaxPrice - newMinPrice) * 0.5 + newMinPrice);
      setMark1((newMaxPrice - newMinPrice) * 0.33 + newMinPrice);
      setMark2((newMaxPrice - newMinPrice) * 0.66 + newMinPrice);
    }
  }, [selectedDestination, travelers, days]);

  const handleDropdownChange = (e) => {
    const selectedName = e.target.value;
    setSelectedDestination(destinationLookup[selectedName]);
  };
  const handleCounterChange = (method, location) => {
    if (method === 'add') {
      if (location === 'days') {
        setDays(days + 1);
      }
      if (location === 'travelers') {
        setTravelers(travelers + 1);
      }
    }
    if (method === 'subtract') {
      if (location === 'days') {
        setDays(days - 1);
      }
      if (location === 'travelers') {
        setTravelers(travelers - 1);
      }
    }
  };

  return (
    <div className={styles['price-widget-container']}>
      <div className={styles['price-widget-input']}>
        <h3>Pricing calculator</h3>
        <h4>Destination</h4>
        <div className={styles['dropdown-wrapper']}>
          <select className={styles['dropdown']} onChange={(e) => handleDropdownChange(e)}>
            {filteredDestinations.length ? (
              filteredDestinations.map((destination) => (
                <option key={destination.name} value={destination.name}>
                  {destination.name}
                </option>
              ))
            ) : (
              // fallback if the API ever fails
              <option value="Argentina">Argentina</option>
            )}
          </select>
        </div>
        <div className={styles['slider-inputs-wrapper']}>
          <div className={styles['travelers-input']}>
            <h4>Travelers</h4>
            <div className={styles['input-buttons']}>
              <Button
                className={styles['input-plus-minus']}
                title="minus"
                variant="plain"
                disabled={travelers <= 1}
                onClick={() => {
                  handleCounterChange('subtract', 'travelers');
                }}
              >
                <Icon icon="remove" size={20} />
              </Button>
              <p className={styles['input-numbers']}>{travelers}</p>
              <Button
                sx={{
                  width: '48px',
                  height: '32px',
                  display: 'inline-block',
                  backgroundColor: 'transparent',
                  borderRadius: '50%'
                }}
                title="plus"
                variant="plain"
                onClick={() => {
                  handleCounterChange('add', 'travelers');
                }}
              >
                <Icon icon="add" size={20} />
              </Button>
            </div>
          </div>
          <div className={styles['days-input']}>
            <h4>Days</h4>
            <div className={styles['input-buttons']}>
              <Button
                className={styles['input-plus-minus']}
                title="minus"
                variant="plain"
                disabled={days <= 4}
                onClick={() => {
                  handleCounterChange('subtract', 'days');
                }}
              >
                <Icon icon="remove" size={20} />
              </Button>
              <p className={styles['input-numbers']}>{days}</p>
              <Button
                className={styles['input-plus-minus']}
                title="plus"
                variant="plain"
                disabled={days >= 30}
                onClick={() => {
                  handleCounterChange('add', 'days');
                }}
              >
                <Icon icon="add" size={20} />
              </Button>
            </div>
          </div>
        </div>

        <p className={styles['inputs-flavor-text']}>
          The higher in price, the more exclusive the experience. The lower in price, the more economic the experience.
        </p>
      </div>
      <div className={styles['price-widget-output']}>
        <div className={styles['slider-container']}>
          <Slider
            defaultValue={price}
            step={1}
            variant="flat"
            value={price}
            min={minPrice}
            max={maxPrice}
            onChange={(value) => {
              setPrice(value);
            }}
            marks={[
              { value: minPrice, label: '$$' },
              { value: mark1, label: '$$$' },
              { value: mark2, label: '$$$$' },
              { value: maxPrice, label: '' }
            ]}
            valueLabelFormatter={(value) => `$${formatNumber(formatPriceStr(value))}`}
            additionalValueLabel={
              <div className={styles['slider-blurb']}>
                <div variant="body2" fontWeight={elsewhere.fontWeight.medium} className={styles['blurb-1']}>
                  ${formatNumber(formatPriceStr(price / travelers))}&nbsp;/&nbsp;traveler
                </div>
                <div variant="body2" color={elsewhere.color['gray-7']} className={styles['blurb-2']}>
                  {travelers}&nbsp;{pluralize('traveler', travelers)},&nbsp;{days}&nbsp;
                  {pluralize('day', days)}
                </div>
              </div>
            }
            showCurrencyCode
          />
        </div>
        <div>
          <InclusionsExclusions removeNotIncluded faqSlot={null}>
            <div variant="caption" className={styles['flight-blurb']}>
              *International flights and trip insurance not included
            </div>
          </InclusionsExclusions>
        </div>
      </div>
    </div>
  );
};

export default GlobalPriceWidget;
