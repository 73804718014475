import React from 'react';

export function IconMobileSupport({ width = 24, height = 24, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="icon-40-support-mobile" transform="translate(-455 -466)">
        <rect
          id="Rectangle_1157"
          data-name="Rectangle 1157"
          width="32"
          height="32"
          transform="translate(455 466)"
          fill="none"
        />
        <path
          id="Path_1719"
          data-name="Path 1719"
          d="M15.307,2.389C15.307-.241,13.126,0,13.126,0H1.968A1.985,1.985,0,0,0,0,2V21.006a1.985,1.985,0,0,0,1.968,2h11.81a1.985,1.985,0,0,0,1.968-2V19.7"
          transform="translate(461.653 470.5)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1717"
          data-name="Path 1717"
          d="M12,18h.011"
          transform="translate(457.521 470.879)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1720"
          data-name="Path 1720"
          d="M12.8,7.76l-1.072,3.216a1.189,1.189,0,0,1-.752.752L7.76,12.8,8.832,9.584a1.189,1.189,0,0,1,.752-.752Z"
          transform="translate(466.663 471.263)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1818"
          data-name="Path 1818"
          d="M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z"
          transform="translate(471 475.6)"
          fill="none"
          stroke="#5b5866"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
