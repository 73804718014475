import { useState } from 'react';
import classnames from 'classnames';

import styles from './HowItWorks.module.scss';

import { Process, Pricing, LocalExperts } from '@components/Homepage/HowItWorks';
import { TABS } from '@constants/homepage/HowItWorks';
import { elementClicked, elementViewed } from '@utils/sendEvent';

export function HowItWorks({ destinationDetails }) {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className={styles['hiw-container']}>
      <div className={styles['hiw-headline']}>
        <h2>How it works</h2>
      </div>
      <div className={styles['hiw-tabs-container']} role="tabpanel">
        {TABS.map((tab, index) => (
          <button
            type="button"
            key={tab}
            role="tab"
            className={classnames(styles['hiw-button-overwrite'], {
              [styles['hiw-tab-selected']]: selectedTab === index
            })}
            onClick={() => {
              setSelectedTab(index);
              elementClicked({
                actionOutcome: 'TOGGLE',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'Tab',
                  location: 'Homepage',
                  name: 'Heading',
                  position: 'HOWITWORKS',
                  text: tab
                }
              });
              elementViewed({
                webElement: {
                  elementType: 'Tab',
                  location: 'Homepage',
                  name: 'Heading',
                  position: 'HOWITWORKS',
                  text: tab
                }
              });
            }}
          >
            <div
              className={classnames(styles['hiw-tab'], {
                [styles['hiw-tab-text-selected']]: selectedTab === index
              })}
            >
              {tab}
            </div>
          </button>
        ))}
      </div>
      {selectedTab === 0 && <Process />}
      {selectedTab === 1 && <Pricing destinationDetails={destinationDetails} />}
      {selectedTab === 2 && <LocalExperts />}
    </div>
  );
}
