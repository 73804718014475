export const TABS = ['Process', 'Pricing', 'Local Experts'];

export const PROCESS_OPTIONS = ['Make a trip request', 'Craft your trip', 'Book your trip', 'Take-off'];
export const MAKE_A_TRIP_REQUEST = {
  p: 'Your adventure starts now. Your local expert will act as your right hand throughout your journey. We work exclusively with award-winning and certified local partners, true destination obsessives who know their home country inside-out.',
  h3: 'Laying the groundwork',
  bulletpoints: [
    'Make a trip request to your desired destination.',
    'It’s simple and fast and will help your local expert pinpoint exactly what you’re looking for.',
    'Once submitted, you will be matched with your local expert.'
  ]
};
export const CRAFT_YOUR_TRIP = {
  p: 'All our trips are built from the ground up with thoughtful personalization and attention to detail. Discuss options and alternatives until your trip is tweaked to perfection.',
  h3: 'No two trips are the same',
  bulletpoints: [
    'Set a meeting with your expert. Think of this conversation as a jumping off place to discuss ideas and inspiration.',
    'Next, your expert will craft your itinerary. To receive a personalized trip proposal, a $100 crafting fee is required.',
    'Sit back and relax. Your expert will craft the best trip around your exact travel dates, pricing, and availability.',
    'Nothing is set in stone. Talk through options and alternatives until your trip is tweaked to perfection.'
  ]
};
export const BOOK_YOUR_TRIP = {
  p: "The planning is officially done. Now it's time to make that dream trip a reality. Next step, secure your trip by paying the deposit. The countdown to take-off is in sight.",
  h3: 'Locking in your plans',
  bulletpoints: [
    'Book by submitting a trip deposit. The deposit is typically 20-40%, and with that, your expert will lock in all your reservations.',
    "Final payment isn't due until 1-2 months before departure.",
    'Leave your expert to do all the heavy lifting to ensure your trip is perfect, from securing reservations, to preparing your roadbook, and calling in favors to friends.'
  ]
};
export const TAKE_OFF = {
  p: 'The countdown is officially over! Your expert is there for you pre-trip, on-trip, and even post-trip with 24/7 support and concierge service. Whatever you need, don’t hesitate.',
  h3: 'It’s go time',
  bulletpoints: [
    'You’ll receive local tips and tricks from your expert pre-departure. This is the secret sauce that makes your trip extra special.',
    'Your expert is there for you throughout, with 24/7 support and concierge service.',
    'Share your favorite moments with us. Tag @elsewhere.io and we’ll repost our favorites.'
  ]
};
export const PROCESS_OPTIONS_MAP = [MAKE_A_TRIP_REQUEST, CRAFT_YOUR_TRIP, BOOK_YOUR_TRIP, TAKE_OFF];

export const FAQ_LOCAL_EXPERTS = [
  {
    h3: 'What is a local expert?',
    p: [
      'Our experts hold some of the highest accolades in the biz. We work exclusively with award-winning and certified local partners, true destination obsessives who have their finger on the pulse of what’s new, what’s hidden, and the things you should avoid. They’re pioneers in sustainable and community tourism and revolutionaries constantly on the quest to uncover new experiences and new places. But best of all, our experts are true locals, they’ve vetted and tested so you don’t have to, and have the personal connections to make anything happen.',
      'And how do they work? Our local experts are trip planners, not on-the-ground guides. They will pour their heart and soul into tailoring the perfect itinerary for you, and act as your on-the-ground support and concierge service while traveling. And if you’re looking for guided experiences, our experts will organize the best for you - think: ethnobotanists for Amazonian superfood foraging or volcanologists on Mount Etna.'
    ]
  },
  {
    h3: 'How do you screen your in-destination experts?',
    p: [
      'Becoming part of the Elsewhere family isn’t easy. Our in-destination experts pass through a series of screenings, rigorous interviews, and audits that span from the inner workings of their business to their portfolio of extraordinary experiences they provide our travelers. Only 3% of agencies that apply to work with us make the cut. Our criteria is tough.',
      'Our first check is to verify their business license, operating license, and insurance policies. We only work with professionals, not just the cool cousin abroad. Next, we dive into sustainability and test their commitment toward responsible travel practices. We review their operations and supplier network to ensure fair wages, low environmental impact, and support of the local community. Once we’ve confirmed they’re good people and we can confidently rely on them to be our wingman in making the world a better place, we test the fun stuff - their portfolio of out-of-this-world activities, accommodations, and surprises. Before making it official, we require them to sign the Elsewhere Charter, which details our terms and communal promises to each other, our travelers, and the destinations impacted.'
    ]
  },
  {
    h3: 'But can’t I just book all travel services myself?',
    p: [
      'Sure, DIY travel planning is always an option. But did you know that 43% of people waste more time researching their vacation than they actually spend on vacation? We know your time is precious. We also know how difficult it is to find reliable information when scrolling through millions of blogs and listings on booking sites.',
      'We’re here to save you time, stress, and energy. It’s our job to cut through the noise with tailored recommendations based on our boots-on-the-ground knowledge and personal connections. We’ve vetted and tested, so you don’t have to.'
    ]
  }
];

export const FAQ_PRICING = [
  {
    h3: 'How does pricing work?',
    p: [
      'When you travel with Elsewhere, we take care of everything (i.e. we are a full service company). This means, a trip with us includes personalized trip planning, booking and reservations, operations and logistics, 24/7 on-the-ground support, and on-trip concierge service.',
      'Included in your trip price is the Elsewhere margin and expert margin. Elsewhere takes 16%, which allows us to operate the platform and includes all the environmental contributions for your trip.',
      'Your expert takes roughly 10-15% (less than you typically tip in a restaurant), which covers the entire service your expert provides. As travel professionals, they have negotiated rates so it can be even less than this range, but their goal is to provide you the best, most unforgettable trip, not the “cheapest.”'
    ]
  },
  {
    h3: 'Are international flights included?',
    p: [
      'No, we specialize in on-the-ground services, so we stick to what we do best. From the moment your plane lands at your destination to the moment you return to the airport for your international flight home, we promise you’ll be in good hands.'
    ]
  }
];
