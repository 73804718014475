import React from 'react';
import ReactModal from 'react-modal';

import styles from './VideoModal.module.scss';

import Icon from '@components/shared/Icon/Icon';

export default function VideoModal({
  children,
  onClose,
  isOpen
}: {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={styles['modal-overlay']}
      closeTimeoutMS={300}
      shouldCloseOnEsc
      onRequestClose={onClose}
      className={styles['modal__wrap']}
      parentSelector={() => document.body}
    >
      <button type="button" className={styles['modal__close-btn']} onClick={onClose}>
        <Icon icon="close" size={18} />
      </button>
      <div className={styles['modal__body']}>{children}</div>
    </ReactModal>
  );
}
