import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './WebflowPage.module.scss';

import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';

const WebflowPage = ({
  children,
  className,
  noInitialShadow,
  commercialPage,
  isShowFooterQA,
  isShowFooterJoinForm,
  pageId
}) => {
  useLayoutEffect(() => {
    const htmlTag = document.querySelector('html');
    const webflowSiteId = '5f68df773d457c61ae9bc926';
    htmlTag.setAttribute('lang', 'en');
    htmlTag.setAttribute('data-wf-page', pageId);
    htmlTag.setAttribute('data-wf-site', webflowSiteId);
    htmlTag.className = 'w-mod-js w-mod-ix';

    return () => {
      htmlTag.removeAttribute('data-wf-page');
      htmlTag.removeAttribute('data-wf-site');
      htmlTag.className = '';
    };
  }, []);
  return (
    <div className={classnames(styles['webflow'], className)}>
      <Header noInitialShadow={noInitialShadow} commercialPage={commercialPage} />
      <div className="webflow-container webflow-elsewhere">{children}</div>
      <Footer isShowFooterQA={isShowFooterQA} isShowFooterJoinForm={isShowFooterJoinForm} />
    </div>
  );
};

WebflowPage.defaultProps = {
  isShowFooterQA: true,
  isShowFooterJoinForm: true,
  noInitialShadow: true,
  commercialPage: true
};

WebflowPage.propTypes = {
  isShowFooterQA: PropTypes.bool,
  isShowFooterJoinForm: PropTypes.bool,
  noInitialShadow: PropTypes.bool,
  commercialPage: PropTypes.bool
};

export default WebflowPage;
