import React from 'react';
import classnames from 'classnames';

import styles from './PressBar.module.scss';

const PressBarSection = () => (
  <div className={classnames(styles['section-press-bar'], 'wf-section')}>
    <div className="slide-wrap-css">
      <div className="mover">
        <img src="/webflow/images/press-bar/vogue-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/authority-mag-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/the-points-guy-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/full-time-travel-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/conde-nast-traveler-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/washington-post-logo.svg" alt="" className="logo-img washington-post" />
        <img src="/webflow/images/press-bar/usa-today-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/well-good-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/here-logo.svg" alt="" className="logo-img here" />
        <img src="/webflow/images/press-bar/skift.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/nyt-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/forbes-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/vogue-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/authority-mag-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/the-points-guy-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/full-time-travel-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/conde-nast-traveler-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/washington-post-logo.svg" alt="" className="logo-img washington-post" />
        <img src="/webflow/images/press-bar/usa-today-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/well-good-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/here-logo.svg" alt="" className="logo-img here" />
        <img src="/webflow/images/press-bar/skift.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/nyt-logo.svg" alt="" className="logo-img" />
        <img src="/webflow/images/press-bar/forbes-logo.svg" alt="" className="logo-img" />
      </div>
    </div>
  </div>
);

export default PressBarSection;
