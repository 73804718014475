import React from 'react';

import styles from './QASection.module.scss';

import routes from '@constants/routes';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';

const HomepageQaSection = () => (
  <section className={styles['homepage-qa']}>
    <h3 className="h2-q-a">Looking to go Elsewhere?</h3>
    <Button
      className="homepage-qa__btn button hollow w-inline-block"
      variant="outline"
      href={routes.destinations.index}
    >
      <div className="btn-text dark">Our destinations</div> <Icon icon="arrow-right" size={16} />
    </Button>
  </section>
);

export default HomepageQaSection;
